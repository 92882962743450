.firstaid-content {
    color: #1D1D2C;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    max-width: 80%;
}

.choke_img {
    
    height: 200px;
}

.choke_flex_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: top;
    
}

.choke_text_position1 {
    position: relative;
    left: -20px;
    top: -50px;
}

.choke_text_position2 {
    position: relative;
    left: -20px;
    top: -50px;
    color: #E40C2B;
    font-size: 1.2rem;
    font-weight: bold;
}

.BITE {
    max-width: 250px;
    float: right;
}

.SUNICON {
    max-width: 250px;
    float: left;
}

.right {
    float: right;
}

.left {
    float: left;
}

.choke_box {
        display: flex;
        flex-direction: row;
        width: 450px;
        justify-content: center;
        align-items: center;
        background: #F7F4E9;
        border-radius: 50%;
        margin: 15px;
}

@media only screen and (max-width: 850px) {
    .choke_flex_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .choke_box {
        width: 400px;
        
}

.choke_text_position1 {
    position: relative;
    left: -20px;
    top: -50px;
    font-size: 1.5rem;
}

.choke_text_position2 {
    position: relative;
    left: -20px;
    top: -50px;

    font-size: 1.1rem;
   
}

}

@media only screen and (max-width: 400px) {

    .choke_box {
        width: 300px;
        
}
    
   
.BITE {
    max-width: 150px;
    
}
  .SUNICON {
    max-width: 150px;
 
}


.choke_img {
    max-width: 150px;
    max-height: 150px;
}
  }