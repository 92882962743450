.foods-content {
    color: black;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.food_list_item {
    width: 300px;

}

.food_list_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
}

.food_name {
    text-align: center;
    margin-bottom: 10px;
}

.toxicity {
    color: #E40C2B;
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
}