.nav-list {
    list-style-type: none;
    position: fixed;
    right: 20px;
    top: 40px;
}

.nav-list li {
    display: inline;
    padding-left: 20px;
}



.nav-button {
    cursor: pointer;
    border-radius: 4px;
    padding: .3rem .5rem;
    background-color: #E40C2B;
    color: #ffffff;
    font-size: 1rem;
    width: 100px; 
}

.nav-button a {
    border-radius: 4px;
    background-color: #E40C2B;
    color: #ffffff;
    font-size: 1rem;
   
}

.modal_img {
    float: left;
    max-width: 160px;
    margin-right: 10px;
}

@media only screen and (max-width: 850px) {
    .nav-button {
    border-radius: 4px;
    padding: .2rem .3rem;
    background-color: #E40C2B;
    color: #ffffff;
    font-size: 1rem;
    width: 90px;
    }

    .nav-button a {
        border-radius: 4px;
        background-color: #E40C2B;
        color: #ffffff;
        font-size: 1rem;
       
    }
    .nav-list {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        position: fixed;
        top:-12px;
    }

    .modal_img {
        float: left;
        max-width: 100px;
        margin-right: 10px;
    }
  }