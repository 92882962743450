.Business {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16.66rem;
  margin: 0 .5rem 2.3rem .5rem;
  background: #ffffff;
  padding: 10px;
}

.Business .image-container {
  margin-bottom: 1rem;
}

.Business h2 {
  margin-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.Business h3 {
 
  font-weight: 300;
}

.category {
  margin-bottom: 10px;
}

.Business-information {
  display: flex;
  justify-content: space-between;
}

.Business-information p {
  font-size: .88rem;
  font-weight: 300;
  line-height: 1rem;
}

.Business-address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Business-reviews {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.Business-reviews h3 {

  font-weight: 600;
}

.Business-reviews .rating {
  font-size: .88rem;
  line-height: 1rem;
}

.pawprint {
  display: block;
  text-align: center;
}

