.hero {
    color: black;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
}



.block-link {
    max-width: 200px;
    max-height: 200px;
    border: 0;
    margin: 25px;
}

.LINK-img {
    width: 250px;
    height: 250px;
    border: 0;
}



@media only screen and (max-width: 600px) {
    
  }