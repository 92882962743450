.plants-content {
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.plant_list_item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 16.66rem;
  margin: 0 .5rem 2.3rem .5rem;
  
  padding: 10px;

}

.plant_list_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.plant_name {
    text-align: center;
    margin-bottom: 10px;
}

.toxicity {
    color: #E40C2B;
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
}