.BusinessList {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 4.4rem 10%;
}

@media only screen and (max-width: 560px) {
  .BusinessList {
    
    margin: 1rem 10%;
  }
}