.Hero {
    max-width: 75%;
}

.Hero-Container {
    background-color: #F7F4E9;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    margin-bottom: 150px;
    min-width: 200px;
    max-width: 700px;
    min-height: 400px;
    max-height: 400px;
    border-radius: 5px;
}

.hero-content {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.HERO-img {
    max-height: 200px;
    position: relative;
    right: -5%;
    top: -20px;
}

.TOXINIMG {
    max-height: 300px;
    position: relative;
    left: -10px;
    top: -200px;
    
   
}

.vet-img {
        max-height: 300px;
        position: relative;
        right: -5%;
        top: -100px;
    }

.firstaid-img {
        max-height: 250px;
       position: relative;
       right: 20px;
    }

.left {
    max-width: 450px;
    float: left;

}

.right {
    max-width: 400px;
    float: right;
}


@media only screen and (max-width: 700px) {
    .block-links-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

.HERO-img {
    max-height: 150px;
  
}

.TOXINIMG {
    max-height: 150px;
    position: static;
}

.vet-img {
        max-height: 180px;
        position: static;
    }

.firstaid-img {
       max-height: 150px;
       position: static;
       
    }

.Hero-Container {
        margin-top: 100px;
        margin-bottom: 150px;
        max-width: 80%;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Hero-Container h3{
       font-size: 1.5rem;
    }


    

  }