/* COLOR SCHEME
RED. #E40C2B
DARK NAVY. #1D1D2C
OFF WHITE. #F7F4E9
TEAL.  #3CBCC3
GOLD. #EDA63F
GREEN.  #438945
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  color:#E40C2B;
  font-size: 3rem;
}

h3 {
  color:#3CBCC3;
  font-size: 2rem;
}

.teal {
  color:#3CBCC3;
}

.red {
  color:#E40C2B;
}

.bold {
  font-weight: bold;
}


ul {
  list-style-type: none;
}

.legal_content{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  background-color: #F7F4E9;
  padding: 15px;
}