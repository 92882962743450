.footer {
 width: 100%;
 background: #F7F4E9;
 color: #1D1D2C;
 bottom: 0px;
 text-align: center;
 position: fixed;
 bottom: 0px;
}

.footer-flex-container {
    display: flex;
    flex-direction: column;
}

h2 {
    text-align: center;
}

.disclaimer-modal {
    text-align: left;
}