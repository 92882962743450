.header{
    width: 100%;
    min-height: 80px;
    background: #F7F4E9;
    margin: 0;
    position: fixed;
    top: 0;
    padding-left: 60px;
    padding-top: 10px;
    z-index: 4;
}

.header-logo {
    height: 150px;
    position: relative;
    left: -50px; 
    z-index: 5;
    
}

.header-left {
    display: flex;
    flex-direction: row;
    background: #E40C2B;
    height: 100px;
    position: relative;
    width: 20px;
}

.header-left:after {
    background: #E40C2B;
    content: "";
    height: 20px;
    left: -40px;
    position: absolute;
    top: 40px;
    width: 100px;
  }

  .title {
      font-size: 5.5rem;
      position: relative;
      left: -45px;
      top: -10px;
  }

  a:link {
    text-decoration: none;
    color:#E40C2B;
  }
  
  a:visited {
    text-decoration: none;
    color:#E40C2B;
  }
  
  a:hover {
    text-decoration: none;
    color:#E40C2B;
  }
  
  a:active {
    text-decoration: none;
    color:#E40C2B;
  }

  @media only screen and (max-width: 400px) {
    .title {
      font-size: 3rem;
      position: relative;
      left: -30px;
      top: -5px;
      z-index: 7;
  }
  .header-logo {
    height: 100px;
    position: relative;
    left: -50px; 
    z-index: 5;
    
}

  }